import './PrivacyPolicy2024October.doc.css';

const PrivacyPolicy2024OctoberDoc = () => (
  <>
    <div>
      <p class="c14 c3">
        <span class="c10 c39"></span>
      </p>
    </div>
    <p class="c41">
      <span class="c29 c17">Leaf Home Privacy Notice</span>
    </p>
    <p class="c2">
      <span class="c0">Last Updated: October 2024</span>
      <span class="c1 c12">&nbsp;</span>
    </p>
    <p class="c2 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c2">
      <span class="c1">
        This Privacy Notice describes the information collected through this website and how that
        information is used and disclosed. The Site is operated by&nbsp;
      </span>
      <span class="c0">Leaf Home&trade; LLC</span>
      <span class="c1">&nbsp;and its subsidiaries, including&nbsp;</span>
      <span class="c0">LeafFilter North, LLC</span>
      <span class="c1">,&nbsp;</span>
      <span class="c0">Leaf Home Safety Solutions, LLC</span>
      <span class="c1">,&nbsp;</span>
      <span class="c0">Leaf Home Water Solutions, LLC</span>
      <span class="c1">, and </span>
      <span class="c0">Leaf Home Enhancements, LLC&nbsp;</span>
      <span class="c1">(together, those entities called &ldquo;</span>
      <span class="c0">Company</span>
      <span class="c1">,&rdquo; &ldquo;</span>
      <span class="c0">we</span>
      <span class="c1">,&rdquo; &ldquo;</span>
      <span class="c0">us</span>
      <span class="c1">,&rdquo; or &ldquo;</span>
      <span class="c0">our</span>
      <span class="c1">
        &rdquo;) and Company and its service providers may collect the types of data and other
        information described in this Privacy Notice as a result of your access to and use of the
        Site (together, &ldquo;
      </span>
      <span class="c0">Collected Information</span>
      <span class="c1 c12">
        &rdquo;). This Privacy Notice also explains your rights and choices regarding your personal
        information, how we communicate changes to this Privacy Notice, and how you may contact us
        regarding questions or issues with respect to anything addressed in this Privacy Notice.
        This Privacy Notice applies to Collected Information that we collect through our this
        website or through any other website on which this Privacy Notice is posted (collectively,
        the &ldquo;Site&rdquo; or &quot;Sites&quot;) and the services we provide, including, without
        limitation, the services we provide through our Sites and Apps (collectively, with the Sites
        and Apps, referred to as our &ldquo;Services&rdquo;) as well as in email, text, and other
        electronic messages between you and Leaf Home, and offline through contact forms or other
        communications with Leaf Home personnel.
      </span>
    </p>
    <p class="c2 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c2">
      <span class="c1">This Privacy Notice is incorporated into and made a part of our&nbsp;</span>
      <span class="c20 c8">
        <a class="c9" target="_blank" href="https://www.leafhome.com/terms-of-use/">
          Terms of Use
        </a>
      </span>
      <span class="c1 c12">
        &nbsp;and any other agreement that references this Privacy Notice or governs access to or
        use of the Services.&nbsp;
      </span>
    </p>
    <p class="c2 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c2">
      <span class="c0">By accessing or using the Services, by accepting the&nbsp;</span>
      <span class="c20 c8">
        <a class="c9" target="_blank" href="https://www.leafhome.com/terms-of-use/">
          Terms of Use
        </a>
      </span>
      <span class="c0">
        , or by entering into any other agreement with Company that references this Privacy Notice
        or governs access to or use of the Services, you (&ldquo;you&rdquo;) agree that you have
        read and understand this Privacy Notice and that you consent to the collection, use, and
        disclosure of your personal information and other Collected Information pursuant to the
        terms of this Privacy Notice.
      </span>
      <span class="c1">&nbsp;</span>
      <span class="c0">
        We reserve the right to update this Privacy Notice at any time and we will update this
        Privacy Notice from time to time, including to reflect changes to the Services and
        Company&rsquo;s products. When we update this Privacy Notice, we will revise the &ldquo;Last
        Updated&rdquo; date at the top of this Privacy Notice. All changes will be effective
        immediately upon posting of the updated Privacy Notice on the Services and, by accessing or
        using the Services after changes are made, you agree to those changes. Material changes will
        be clearly posted on the Services or otherwise communicated to you.
      </span>
    </p>
    <p class="c19">
      <span class="c29 c17">Types of Collected Information</span>
    </p>
    <p class="c2">
      <span class="c1">
        The information collected as a result of your access to or use of the Services generally
        falls into the following categories:
      </span>
    </p>
    <ul class="c27 lst-kix_list_12-0 start">
      <li class="c2 c26 li-bullet-0">
        <span class="c1">
          Information that you voluntarily submit using the Services, for example, your contact
          information, requests for services, and any images or other content that you may choose to
          provide.
        </span>
      </li>
      <li class="c2 c26 li-bullet-0">
        <span class="c1">
          Other information collected through your use of the Services, for example, data collected
          by the servers used to operate the&nbsp;Services&nbsp;(e.g., IP addresses and device
          information), location information, data collected by analytics and advertising services,
          data collected when you connect using or share information via social media platforms, and
          data collected by cookies and tracking technologies.
        </span>
      </li>
    </ul>
    <p class="c19">
      <span class="c29 c17">Information You Submit</span>
    </p>
    <p class="c2">
      <span class="c1 c12">
        We will collect any and all information that you choose to submit through, using, or in
        connection with the Services, including, without limitation, the types of information
        described below.&nbsp;
      </span>
    </p>
    <p class="c2 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c2">
      <span class="c0">Personal Information:</span>
      <span class="c1 c12">
        &nbsp;You can visit the Services without submitting your personal information to us but you
        will be required to provide your personal information in order to request information from
        us, to activate or sign into your customer account (where that option is available), to
        purchase products or services using the Services (where those options are available), to
        participate in Company promotions or contests, and to use certain other tools and features
        of the Services. We will collect any personal information from or about you that you choose
        to provide, which may include, without limitation, your name, email address, telephone
        number, physical address, and other contact information. We will use your personal
        information as described below in this Privacy Notice, including to provide the Services and
        to communicate with you. If you provide a mobile or other telephone number to us, you
        consent to receiving telephone calls from us at that telephone number as described below in
        this Privacy Notice, but only to the extent permitted by applicable law.&nbsp;
      </span>
    </p>
    <p class="c2 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c2">
      <span class="c0">Messages and Requests for Quotes, Estimates, and Information:</span>
      <span class="c1 c12">
        &nbsp;To request a quote for a product or service, to request information, or to send us a
        message via the Services, you may need to provide your email address, telephone number, and
        other contact information, as well as, as applicable, details of the products, services, or
        information in which you are interested. If you use any chat or similar feature on the
        Services, we will collect all text and other information and content that you may provide or
        input using those features.&nbsp;
      </span>
    </p>
    <p class="c2 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c2">
      <span class="c0 c24">Phone Numbers</span>
      <span class="c0">.</span>
      <span class="c1 c12">
        &nbsp;If you provide a mobile or other telephone number to us, you consent to receiving text
        messages and telephone calls from us at that telephone number as described below, but only
        to the extent permitted by applicable law.
      </span>
    </p>
    <p class="c2 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c2">
      <span class="c0">Marketing Communications:</span>
      <span class="c1">
        &nbsp;If you sign up or otherwise opt in to receive updates, promotional communications, or
        related materials from us, you will need to provide your email address, mailing address, or
        telephone number. We will use this information to provide you with promotional and other
        communications pertaining to our products and services via email, physical mail, or
        telephone, as applicable.&nbsp;
      </span>
      <span class="c0">
        You may opt out of receiving promotional or marketing communications from us at any time by
        following the unsubscribe instructions in the applicable email or by contacting us using the
        information below under &ldquo;How to Contact Us.&rdquo;
      </span>
      <span class="c1 c12">
        &nbsp;We may use third-party providers to deliver these communications to you.&nbsp;
      </span>
    </p>
    <p class="c2 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c2">
      <span class="c0">Careers:</span>
      <span class="c1 c12">
        &nbsp;The Services may allow you to submit a job application or related materials to us. We
        may use that information to evaluate your qualifications and consider or respond to your
        inquiry or application. Your submission of a resume, job application, or related materials
        does not in any way require Company to review that information or consider you for
        employment or any other opportunity. To view career opportunities at Company or to submit a
        job application you may be redirected to an online career portal operated by a third party.
        We encourage you to review any terms and privacy policies posted on that portal.
      </span>
    </p>
    <p class="c2 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c2">
      <span class="c0">Our Social Media Pages:</span>
      <span class="c1 c12">
        &nbsp;Company is active on social media. You may have the opportunity to comment on social
        media platforms regarding Company and the products and services we offer or to submit
        related photos or other materials. Company reserves the right to post on the Services, other
        websites, and social media pages any comments or content that you post on our social media
        pages or provide to us through social media.
      </span>
    </p>
    <p class="c2 c3">
      <span class="c1 c12"></span>
    </p>
    <p class="c2">
      <span class="c0">User Experience Information:</span>
      <span class="c1">
        &nbsp;In order to perform quality assurance and to continuously improve our customers&rsquo;
        experience, to assist with fraud identification, and to assist our customer relationship
        representatives (for example, through representatives in our call center), we use tools to
        monitor certain user experience information, including, without limitation, audio of
        customer service calls and information resulting from such calls.
      </span>
    </p>
    <p class="c2 c3">
      <span class="c12 c17 c29"></span>
    </p>
    <p class="c2">
      <span class="c29 c17">Other Information Collected</span>
    </p>
    <p class="c2">
      <span class="c1 c12">
        As you use the Services, certain information can be passively collected&mdash;that is,
        gathered without the user actively submitting the information or being concurrently made
        aware of the collection of information&mdash;using various technologies. Information is
        passively collected in a variety of ways, including as described below.&nbsp;
      </span>
    </p>
    <p class="c2 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c2">
      <span class="c0">IP Addresses and Related Data:</span>
      <span class="c1 c12">
        &nbsp;We will collect data pertaining to you and the equipment, software, and communication
        methods you use to access the Internet and the Services, including the search engine(s) and
        the keyword(s) you used to find the Services, the pages you access on the Services, the
        websites you access before and after visiting the Services, web browser type and add-ons,
        screen resolution, date and time stamps, Services traffic analysis, clickstream data,
        approximate geographic location, performance data, Internet protocol (&ldquo;IP&rdquo;)
        addresses assigned to the computers and other devices from where you access the Internet,
        your Internet service provider (ISP), device ID numbers and unique identifiers, media access
        control (MAC) addresses, and operating system information. Company may use this information
        to administer the Services and its servers, to generate statistical information, to monitor
        and analyze Services traffic and usage patterns, to monitor and help prevent fraud, to
        investigate complaints and violations of our policies, and to improve the Services.&nbsp;
      </span>
    </p>
    <p class="c2 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c2">
      <span class="c0">Analytics:</span>
      <span class="c1">
        &nbsp;The Services uses third-party analytics tools (e.g., Google Analytics, Hotjar) to
        collect and process data about your use of the Services, including when you visit the
        Services, URLs of the websites that you visit prior to visiting the Services and when you
        visit those websites, and IP addresses assigned to the devices from where you access the
        Internet. Our analytics providers may set and read cookies to collect this data and your web
        browser will automatically send data collected by those cookies to our analytics providers.
        Our analytics providers use this data to provide us with reports that we will use to improve
        the Services&rsquo; structure and content. For more information on how Google uses this
        data, visit&nbsp;
      </span>
      <span class="c20 c8">
        <a class="c9" target="_blank" href="https://policies.google.com/privacy">
          Google&rsquo;s Privacy Policy
        </a>
      </span>
      <span class="c1">&nbsp;and&nbsp;</span>
      <span class="c20 c8">
        <a class="c9" target="_blank" href="https://policies.google.com/technologies/partner-sites">
          Google&rsquo;s page on How Google uses data when you use our partners&rsquo; sites or apps
        </a>
      </span>
      <span class="c1">
        . To prevent this data from being used by Google Analytics, follow the instructions to
        download and install the&nbsp;
      </span>
      <span class="c20 c8">
        <a class="c9" target="_blank" href="https://tools.google.com/dlpage/gaoptout">
          Google Analytics Opt-out Browser Add-on
        </a>
      </span>
      <span class="c1">
        &nbsp;for each browser you use. Using the Google Analytics Opt-out Browser Add-on will not
        prevent Company from using other analytics tools and will not prevent data from being sent
        to the Services itself or to Google. For more information about how Google Analytics uses
        cookies to measure user interactions on websites, visit&nbsp;
      </span>
      <span class="c20 c8">
        <a
          class="c9"
          target="_blank"
          href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
        >
          Google Analytics Cookie Usage on Websites
        </a>
      </span>
      <span class="c1">
        . You may disable cookies as discussed below, but that may impact your use and enjoyment of
        the Services. You may opt out to Hotjar&rsquo;s creation of a user profile, Hotjar&rsquo;s
        storing of data about your usage of the Services, and Hotjar&rsquo;s use of tracking cookies
        on other websites by opting out&nbsp;
      </span>
      <span class="c20 c8">
        <a class="c9" target="_blank" href="https://www.hotjar.com/policies/do-not-track/">
          here
        </a>
      </span>
      <span class="c1 c12">.&nbsp;</span>
    </p>
    <p class="c2 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c2">
      <span class="c0">Online Advertising, Advertising Networks, and Retargeting:</span>
      <span class="c1 c12">
        &nbsp;Some companies that help Company deliver interactive online advertising, such as
        banner ads, may collect and use information about Services visitors to help Company better
        understand the types of advertising or promotions that are most appealing to its customers.
        Company may also participate in advertising networks and related services that are managed
        and provided by third-party agencies, technology vendors, and research firms, including
        Google Ads and advertising services provided by Meta (sometimes referred to the Meta
        &ldquo;pixel&rdquo;) and Pinterest. These services collect information about your
        interactions with the Services and other websites and will use that information to target
        advertisements for goods and services and to display those advertisements on other websites.
        The information collected may be associated with your personal information.
      </span>
    </p>
    <p class="c2 c3">
      <span class="c1 c12"></span>
    </p>
    <p class="c2">
      <span class="c1 c12">
        Through Google Ads, Google uses your Internet searches, cookies, and similar identifiers
        (e.g., pixel tags) to collect information about your visits to the Service and your
        interaction with our products and services to generate targeted advertisements to you on
        other websites that you visit across the Internet. We may also enable and implement Google
        Analytics Advertising Features on the Services.
      </span>
    </p>
    <p class="c2 c3">
      <span class="c1 c12"></span>
    </p>
    <p class="c2">
      <span class="c1">
        To opt out of remarketing advertising provided through Google, to customize your ad
        preferences, or to limit Google&rsquo;s collection or use this information, visit&nbsp;
      </span>
      <span class="c20 c8">
        <a class="c9" target="_blank" href="https://safety.google/">
          Google&rsquo;s Safety Center
        </a>
      </span>
      <span class="c1">&nbsp;and&nbsp;</span>
      <span class="c20 c8">
        <a class="c9" target="_blank" href="https://www.google.com/settings/ads">
          Google&rsquo;s Ad Settings
        </a>
      </span>
      <span class="c1">&nbsp;and follow&nbsp;</span>
      <span class="c20 c8">
        <a
          class="c9"
          target="_blank"
          href="https://support.google.com/ads/answer/2662922?hl%253Den%2526ref_topic%253D7048998"
        >
          Google&rsquo;s personalized ad opt-out instructions
        </a>
      </span>
      <span class="c1">. To modify your Outbrain profile settings, click&nbsp;</span>
      <span class="c20 c8">
        <a class="c9" target="_blank" href="https://my.outbrain.com/recommendations-settings/home">
          here
        </a>
      </span>
      <span class="c1">
        . If you have a Facebook account but do not wish Meta to collect data relating to you via
        the Services, we suggest that you: (a) hide social media platform plug-ins using an ad
        blocker; (b) always log off completely from Facebook before visiting other websites and
        delete all related cookies; and, (c) where the option is available, disable the advertising
        services and networks operated by Meta.&nbsp;
      </span>
      <span class="c0">
        To change your preferences with respect to certain online ads and to obtain more information
        about third-party ad networks and online behavioral advertising, please visit the{' '}
      </span>
      <span class="c42 c25">
        <a class="c9" target="_blank" href="http://www.networkadvertising.org/choices">
          National Advertising Initiative Consumer opt-out page
        </a>
      </span>
      <span class="c0">&nbsp;or the </span>
      <span class="c42 c25">
        <a class="c9" target="_blank" href="http://www.aboutads.info/">
          Digital Advertising Alliance Self-Regulatory Program
        </a>
      </span>
      <span class="c0">.</span>
      <span class="c1 c12">
        &nbsp;Please remember that changing your settings with individual web browsers or ad
        networks will not necessarily carry over to other browsers or ad networks. As a result,
        depending on the opt-outs you request, you may still see our ads from time to
        time.&nbsp;Your device may also include a feature (&ldquo;Limit Ad Tracking&rdquo; on iOS or
        &ldquo;Opt Out of Interest-Based Ads&rdquo; on Android) that allows you to opt out of having
        certain information collected through apps used for behavioral advertising purposes.
      </span>
    </p>
    <p class="c2 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c2">
      <span class="c0">Social Media Plug-Ins &amp; Links:</span>
      <span class="c1 c12">
        &nbsp;The Services may allow you to connect to and share information with social media
        platforms and we may be required to implement cookies, plug-ins, and APIs provided by those
        social media platforms in order to facilitate those features. We may share information that
        you provide to us or that we may collect about your use of the Services with those platforms
        and that information will be subject to those platforms&rsquo; privacy policies. We
        encourage you to review the privacy policy of any social media platform that you use in
        connection with the Services. We cannot control any policies or terms of third-party
        platforms. As a result, we cannot be responsible for any use or disclosure of your
        information or content by third-party platforms, which you use at your own risk.&nbsp;
      </span>
    </p>
    <p class="c2 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c2">
      <span class="c0">Cookies and Tracking Technologies:</span>
      <span class="c1">
        &nbsp;We use technologies, such as cookies and web beacons, to collect information about the
        Services pages you view, the links you click and other actions you take on the Services.
        Cookies are small pieces of information that are stored on your device&rsquo;s hard drive by
        your web browser. We use cookies to learn and track how you access and use the Services,
        including when and how users visit the Services and how popular particular Services pages
        are and aren&rsquo;t. We also use cookies to recognize Services users, to help display
        information on the Services, and to improve your enjoyment and the usefulness of the
        Services, for example, by remembering and displaying certain information. The types of
        information collected by a cookie and the purposes for which that information is used
        depends on the type of cookie. By way of illustration, the types of cookies that the
        Services may use, the types of information collected by those cookies, and their purposes
        are described below:
      </span>
    </p>
    <ul class="c27 lst-kix_list_13-0 start">
      <li class="c4 c5 li-bullet-0">
        <span class="c8 c21 c24">
          Operationally necessary cookies (also called essential cookies).
        </span>
        <span class="c10 c8">
          &nbsp;These are cookies that are required for the operation of the Services. For example,
          these cookies are required to identify irregular website behavior, prevent fraudulent
          activity, and improve security. They also allow users of the Services to make use of its
          functions. Without these cookies, features and services you have requested will not be
          able to be provided.
        </span>
      </li>
    </ul>
    <ul class="c27 lst-kix_list_14-0 start">
      <li class="c4 c5 li-bullet-0">
        <span class="c8 c21 c24">Functional cookies.</span>
        <span class="c10 c8">
          &nbsp;These cookies allow us to offer you enhanced functionality when accessing or using
          the Services. This may include to remembering choices you make, for example, remembering
          your preferences or settings, remembering if you reacted to something on or through the
          Services so that you are not asked to react to it again, remembering if you have used any
          feature of the Services before, remembering your username or preferences, remembering if
          you have used any feature of the Services before, restricting the number of times you are
          shown a particular advertisement, and enabling social media components. If functional
          cookies are disabled, various functions of the Services may be unavailable to you or may
          not work the way you want them to.
        </span>
      </li>
    </ul>
    <ul class="c27 lst-kix_list_15-0 start">
      <li class="c4 c5 li-bullet-0">
        <span class="c8 c21 c24">Performance cookies (also called analytical cookies).</span>
        <span class="c10 c8">
          &nbsp;These cookies assess the performance of the Services, including as part of our
          analytic practices to help us understand how visitors use and interact with the Services,
          for example, which pages on our websites users visit most often. These cookies also enable
          us to personalize content and remember your preferences. These cookies help us improve the
          way our websites work and provide a better, personalized user experience.
        </span>
      </li>
    </ul>
    <ul class="c27 lst-kix_list_16-0 start">
      <li class="c4 c5 li-bullet-0">
        <span class="c8 c21 c24">Advertising or targeted cookies.</span>
        <span class="c8 c21">
          &nbsp;These cookies record your visits to the Services, the pages you visit on our
          websites, and the links you have clicked. They gather information about your browsing
          habits and remember that you have visited a particular website. We and our third-party
          advertising platforms or networks may use this information to make the Services and its
          content more relevant to your interests (this is sometimes called &ldquo;behavioral&rdquo;
          or &ldquo;targeted&rdquo; advertising and is further discussed above). These cookies are
          also used to limit the number of times you see an advertisement and to help measure the
          effectiveness of advertising campaigns. To find out more about interest-based ads and your
          choices, please visit the&nbsp;
        </span>
        <span class="c20 c8">
          <a class="c9" target="_blank" href="http://digitaladvertisingalliance.org/">
            Digital Advertising Alliance
          </a>
        </span>
        <span class="c8 c21">, the&nbsp;</span>
        <span class="c20 c8">
          <a class="c9" target="_blank" href="https://www.networkadvertising.org/">
            Network Advertising Initiative
          </a>
        </span>
        <span class="c8 c21">, the&nbsp;</span>
        <span class="c20 c8">
          <a class="c9" target="_blank" href="https://www.iabeurope.eu/">
            Interactive Advertising Bureau (IAB) Europe
          </a>
        </span>
        <span class="c8 c21">,&nbsp;</span>
        <span class="c20 c8">
          <a class="c9" target="_blank" href="http://www.allaboutcookies.org/">
            http://www.allaboutcookies.org
          </a>
        </span>
        <span class="c8 c21">, and&nbsp;</span>
        <span class="c20 c8">
          <a class="c9" target="_blank" href="http://www.youronlinechoices.com/">
            http://www.youronlinechoices.com
          </a>
        </span>
        <span class="c10 c8">.</span>
      </li>
    </ul>
    <p class="c2">
      <span class="c1 c12">
        The Services may use both cookies that we implement and cookies implemented by our suppliers
        and other third parties. Most browsers automatically accept cookies. Browsers generally also
        allow users to manage cookies in the browser&rsquo;s settings. You can disable or limit
        cookies but doing so may impact your use and enjoyment of the Services and other websites.
        For example, the Services may not be able to be personalized for you, may no longer capture
        or remember your preferences or other choices you have made on the Services in the past, and
        may not remember you or recognize the devices you use to access the Services. Changing your
        cookie preferences in one browser will not necessarily carry over to other browsers, so you
        may need to adjust your preferences each time you get a new device, install a new browser,
        upgrade an existing browser, or alter or delete a browser&rsquo;s cookie file. The Services
        may contain electronic images known as web beacons (sometimes called single-pixel GIFs or
        clear GIFs), which can be used to assist in delivering cookies on the Services, to allow us
        to count users who have visited those pages, marketing communications as outlined in this
        Privacy Notice, and to provide personalized advertising. We may include web beacons, pixel
        tags, and similar technologies in Company&rsquo;s promotional email messages and other
        electronic communications in order to determine whether or not messages have been opened or
        acted upon and identify the links you click on.&nbsp;
      </span>
    </p>
    <p class="c2 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c2">
      <span class="c0">How We Respond to Do-Not-Track Signals:</span>
      <span class="c1">
        &nbsp;Due to the automatic collection of data using cookies as described above, we do not
        honor &ldquo;do not track&rdquo; requests. For this reason, your selection of the &ldquo;Do
        Not Track&rdquo; option provided by your browser may not have any effect on our collection
        of information using cookies and tracking technologies.
      </span>
      <span class="c8 c21">&nbsp;</span>
      <span class="c1">
        However, residents of certain U.S. states may opt out by broadcasting an Opt-Out Preference
        Signal under applicable law, including the Global Privacy Control (GPC), depending on which
        browsers and/or browser extensions you use that may support such a signal. To look at
        extensions and browsers supporting the GPC browser signal, visit here:&nbsp;
      </span>
      <span class="c20 c8">
        <a class="c9" target="_blank" href="https://globalprivacycontrol.org/">
          https://globalprivacycontrol.org/
        </a>
      </span>
      <span class="c1 c12">
        . Note that if you choose to use the GPC signal, you will need to turn it on for each
        supported browser or browser extension you use.
      </span>
    </p>
    <p class="c19">
      <span class="c29 c17">How Collected Information is Used and Shared</span>
    </p>
    <p class="c2">
      <span class="c1 c12">
        In addition to the uses and sharing described above, Company and its suppliers may use and
        disclose Collected Information as described below. Except as described in this Privacy
        Notice or as may be required or permitted by applicable law, we do not use, sell, rent,
        share, or otherwise disclose Collected Information.&nbsp;
      </span>
    </p>
    <p class="c2 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c2">
      <span class="c0">Purpose Collected and Communication with You:</span>
      <span class="c1 c12">
        &nbsp;We will store, use, and share your personal information and other Collected
        Information for the purpose for which it was collected (for example, to process and
        communicate with you, to administer your customer account and maintain your purchase
        history, and to provide support to Services users) and to provide you with information and
        communications that you request. We may send you service communications such as welcome
        letters, those regarding estimates and service scheduling, billing reminders, information on
        technical service issues, security announcements, and requests for verification of
        information. You can opt out of receiving promotional emails at any time by following the
        steps as described in the applicable email. If you provide us with a telephone number, we
        may call you in response to your questions or comments or with respect to your orders and,
        as described below, we may send you text messages.&nbsp;
      </span>
    </p>
    <p class="c2 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c2">
      <span class="c0">Service Quality Monitoring:</span>
      <span class="c1 c12">
        &nbsp;Some transactions initiated through the Services may require you to call Company or
        require us to call you, for example, to confirm orders or advise on order status. Customers
        should be aware that it is Company&rsquo;s practice to monitor and, in some cases, record,
        in accordance with applicable law, such calls for staff training or quality assurance
        purposes.&nbsp;
      </span>
    </p>
    <p class="c2 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c2">
      <span class="c0">Text Messages; Phone Calls:</span>
      <span class="c1 c12">
        &nbsp;If you opt in to receiving text messages and/or telephone calls on the Services when
        prompted, Company may send you autodialed and other text messages at the telephone number
        you provide relating to your use of the Services. Consenting to receiving text messages is
        not required to buy goods or services or to otherwise do business with us. Message and data
        rates may apply.&nbsp;No text message opt-in status information will be shared with or sold
        to third parties/affiliates for their own marketing/promotional purposes. Text messaging
        originator opt-in data and consent information will not be shared with any third parties,
        including affiliate or business partners, except for fraud prevention and to our third
        parties solely for the purpose of providing the text message campaigns and communications to
        you. We also use and disclose your information pursuant to a subpoena, court order or other
        legal process, to establish or exercise our legal rights, or defend against legal claims.
        The opt-in is not transferable to another party involved in the process.
      </span>
    </p>
    <p class="c2 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c2">
      <span class="c0">Sharing Collected Information with our Subsidiary:</span>
      <span class="c1 c12">
        &nbsp;Based on how you use the Services and the information that you provide to or request
        from us, we may share your name, contact information, and related Collected Information with
        our related subsidiary companies who have products or services that we think may be of
        interest to you. Our subsidiary companies may contact you directly with respect to such
        products and services. You may opt out of receiving communications from any such subsidiary
        company pursuant to these policies. You may opt out of receiving communications from us by
        contacting us using the information below under &ldquo;How to Contact Us.&rdquo;
      </span>
    </p>
    <p class="c2 c3">
      <span class="c1 c12"></span>
    </p>
    <p class="c2">
      <span class="c0">Marketing Cooperative:</span>
      <span class="c1 c12">
        &nbsp;To the extent permitted under applicable law, we share your Collected Information
        (including, for example, your name, address and commercial information about the services
        and products you&rsquo;ve purchased) with third party companies who participate in
        advertising networks and cooperatives that we also participate in. Such third party
        businesses use that information to send you direct mail marketing and promotional
        communications about services and products that you may be interested in.&nbsp;
      </span>
    </p>
    <p class="c2 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c2">
      <span class="c0">
        Evaluation and Improvement of Our and Our Subsidiaries&rsquo; Offerings:
      </span>
      <span class="c1 c12">
        &nbsp;Company and its subsidiaries may use Collected Information to analyze, develop, and
        improve their respective products and services (including the Services), improve or monitor
        our customer services, to inform marketing and communication plans and strategies, and to
        understand and evaluate customer needs and preferences. Such uses include, by way of
        illustration, to provide you with more effective customer service, to make the Services or
        our services easier to use; to perform research and analysis aimed at improving our
        products, services, and technologies, and to display content and advertising that are
        customized to your interests and preferences.&nbsp;
      </span>
    </p>
    <p class="c2 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c2">
      <span class="c0">Sharing Information with Our Service Providers:</span>
      <span class="c1 c12">
        &nbsp;We will disclose Collected Information to our and our subsidiaries&rsquo; respective
        suppliers, vendors, and service providers as necessary for us to provide the Services and
        our products and services to you and to engage in the use and sharing of Collected
        Information as permitted by this Privacy Notice. These third parties include, without
        limitation, our website management and hosting suppliers, customer support providers, those
        handling the processing and delivery of mailings, cloud storage providers, website analytics
        providers, payment processors (if and as applicable), product installers, and email service
        suppliers. Our service providers are authorized to and may use and disclose Collected
        Information as necessary for them to provide the applicable products and services to us and
        as provided by their own privacy policies and our agreements with those third parties.&nbsp;
      </span>
    </p>
    <p class="c2 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c2">
      <span class="c0">Data Aggregation and Analysis:</span>
      <span class="c1 c12">
        &nbsp;We may anonymize and aggregate personal information and user-generated content, and
        aggregate other Collected Information, and use and disclose that aggregate data in any
        manner for our business purposes, in each case to the extent permitted by applicable law.
        Such purposes include to understand Services users&rsquo; behavior and usage patterns, to
        improve and enrich our products and services, to determine what kinds of products and
        services we can provide, the monetization of such aggregate data, and to license, sell, and
        otherwise distribute aggregate data to third parties. We may provide aggregate information
        to our subsidiaries, suppliers, and other third parties for these purposes.&nbsp;
      </span>
    </p>
    <p class="c2 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c2">
      <span class="c0">Combination with Other Information:</span>
      <span class="c1 c12">
        &nbsp;We may combine Collected Information, as well as aggregate information derived from
        such information, with other information in our possession (e.g., that related to our
        provision of products or services to you, customer account information, data licensed from
        third parties) for purposes of using and disclosing information as described in this Privacy
        Notice, including to offer you a more consistent and personalized experience in your
        interactions with Company.&nbsp;
      </span>
    </p>
    <p class="c2 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c2">
      <span class="c0">Mergers and Acquisitions:</span>
      <span class="c1 c12">
        &nbsp;Circumstances may arise where, for business reasons, Company decides to sell, buy,
        merge, or otherwise reorganize its businesses or assets. Collected Information may be
        transferred, sold, shared, or otherwise disclosed to the subsequent owner(s) or successor(s)
        of those transactions and in connection with the evaluation of those transactions. Such
        recipients will be bound by this Privacy Notice as it applies to the information
        disclosed.&nbsp;
      </span>
    </p>
    <p class="c2 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c2">
      <span class="c0">Lawful Processes and Protection of Company:</span>
      <span class="c1 c12">
        &nbsp;We may use and share Collected Information in accordance with and in response to
        regulatory authorities, courts, law enforcement and governmental agency requests, emergency
        services, and other necessary third parties for legal, protection, security, and safety
        purposes (e.g., to comply with laws or regulations, to respond to subpoenas and legal
        processes, to perform credit checks, or to protect the safety of our employees, agents,
        customers, or any other person). We may use Collected Information and share it with third
        parties if we believe doing so is necessary or appropriate to protect our rights or the
        rights of others, including to enforce our agreements, policies, and terms, to bring legal
        action, to protect our operations and assets, or to pursue remedies or limit damages that we
        may sustain.&nbsp;
      </span>
    </p>
    <p class="c2 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c2">
      <span class="c0">With Your Consent:</span>
      <span class="c1">
        &nbsp;With your consent, we may use and share your personal information and other Collected
        Information in ways not specifically described in this Privacy Notice.
      </span>
    </p>
    <p class="c19">
      <span class="c29 c17">Additional Information For Applicable U.S. State Residents</span>
    </p>
    <p class="c4">
      <span class="c10 c8">
        Certain U.S. states require additional disclosures and provide users residing in such U.S.
        states with additional choices and rights. This section of the Privacy Notice only applies
        to individuals located in such U.S. and where such U.S. state data protection laws apply to
        Company&rsquo;s processing of your personal information.
      </span>
    </p>
    <p class="c4 c3">
      <span class="c22 c21"></span>
    </p>
    <p class="c4">
      <span class="c25 c21 c24">Collection and Use</span>
      <span class="c8 c21">
        . In addition to, and consistent with, the uses and sharing or other purposes described
        elsewhere in this Privacy&nbsp;
      </span>
      <span class="c1">Notice</span>
      <span class="c10 c8">
        , we and our suppliers may use and disclose Collected Information as described below:
      </span>
    </p>
    <p class="c4 c3">
      <span class="c10 c8"></span>
    </p>
    <table class="c45">
      <tr class="c7">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c4">
            <span class="c25 c21">Personal Information</span>
          </p>
        </td>
        <td class="c36" colspan="1" rowspan="1">
          <p class="c4">
            <span class="c25 c21">
              We collect the following subcategories of personal information:
            </span>
          </p>
        </td>
        <td class="c38" colspan="1" rowspan="1">
          <p class="c4">
            <span class="c25 c21">
              We collect such personal information for the following purposes:
            </span>
          </p>
        </td>
      </tr>
      <tr class="c32">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c4">
            <span class="c25 c21">Identifiers</span>
          </p>
        </td>
        <td class="c36" colspan="1" rowspan="1">
          <p class="c4">
            <span class="c10 c8">
              First and last name, physical address, telephone number, cellphone number, email
              address.
            </span>
          </p>
        </td>
        <td class="c38" colspan="1" rowspan="1">
          <p class="c4">
            <span class="c10 c8">
              Performing services or providing products to you, providing customer services,
              verifying user information, facilitating access to services or products.
            </span>
          </p>
          <p class="c4">
            <span class="c10 c8">
              To communicate with you and send you information about our products or services, or to
              respond to your request for information or products and services.
            </span>
          </p>
          <p class="c4">
            <span class="c10 c8">
              To send promotional communications about us, to the extent permitted by applicable
              law.
            </span>
          </p>
        </td>
      </tr>
      <tr class="c32">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c4">
            <span class="c10 c25">Commercial Information</span>
          </p>
        </td>
        <td class="c36" colspan="1" rowspan="1">
          <p class="c4">
            <span class="c10 c8">
              Amount and types of orders, Services and products requested or purchased, shipping
              information.
            </span>
          </p>
        </td>
        <td class="c38" colspan="1" rowspan="1">
          <p class="c4">
            <span class="c10 c8">
              Performing services or providing products to you, providing customer services,
              verifying user information, facilitating access to services or products.
            </span>
          </p>
          <p class="c4">
            <span class="c10 c8">
              To communicate with you and send you information about our products or services, or to
              respond to your request for information or products and services.
            </span>
          </p>
          <p class="c4">
            <span class="c10 c8">
              To send promotional communications about us, to the extent permitted by applicable
              law.
            </span>
          </p>
        </td>
      </tr>
      <tr class="c50">
        <td class="c11" colspan="1" rowspan="2">
          <p class="c4">
            <span class="c25 c21">Internet or other electronic network activity information</span>
          </p>
        </td>
        <td class="c36" colspan="1" rowspan="2">
          <p class="c4">
            <span class="c10 c8">
              Consistent with and in addition to the &ldquo;Other Information Collected&rdquo;
              section above:
            </span>
          </p>
          <p class="c4">
            <span class="c10 c8">
              IP addresses, log files, login information, and cookies and device information (e.g.,
              online activity such as date and time of accessing our websites, website addresses
              accessed, the length of website visit).
            </span>
          </p>
        </td>
        <td class="c38" colspan="1" rowspan="2">
          <p class="c4">
            <span class="c10 c8">
              Analytical purposes, including, without limitation, to help us improve your access to
              the Website (for example, by remembering your contact and other information when you
              access or use the Website), to identify repeat visitors to the Website, to track how
              you access and use the Website, to learn when and how users visit the Website, to
              learn how popular Website pages are and aren&rsquo;t, to learn which search terms are
              used to find the Website, to learn which websites direct you to the Website, to help
              display certain information on the Website, and to improve your enjoyment of the
              Website.
            </span>
          </p>
          <p class="c4">
            <span class="c10 c8">
              To target advertisements for goods and services and to display those advertisements on
              other websites.
            </span>
          </p>
          <p class="c4">
            <span class="c10 c8">
              To administer the Website and its servers, to generate statistical information, to
              monitor and analyze Website traffic and usage patterns, to monitor and help prevent
              fraud, to investigate complaints and violations of our policies, and to improve the
              Website.
            </span>
          </p>
        </td>
      </tr>
      <tr class="c52">
        <td class="c15" colspan="1" rowspan="1">
          <p class="c3 c34">
            <span class="c8 c10"></span>
          </p>
        </td>
      </tr>
      <tr class="c44">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c4">
            <span class="c25 c21">Approximate Geolocation</span>
          </p>
        </td>
        <td class="c36" colspan="1" rowspan="1">
          <p class="c4">
            <span class="c10 c8">Physical address; work / business address, state, region.</span>
          </p>
        </td>
        <td class="c38" colspan="1" rowspan="1">
          <p class="c4">
            <span class="c10 c8">
              To communicate with you and send you information about our products or services, or to
              respond to your request for information or products and services.
            </span>
          </p>
          <p class="c4">
            <span class="c10 c8">
              To send you promotional communications about us and our products or services, to the
              extent permitted by law.
            </span>
          </p>
          <p class="c4">
            <span class="c10 c8">
              To ship products to you and fulfill orders you have requested.
            </span>
          </p>
        </td>
        <td class="c15" colspan="1" rowspan="1">
          <p class="c34 c3">
            <span class="c10 c33"></span>
          </p>
        </td>
      </tr>
      <tr class="c40">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c4">
            <span class="c25 c21">Professional or employment-related information</span>
          </p>
        </td>
        <td class="c36" colspan="1" rowspan="1">
          <p class="c4">
            <span class="c10 c8">
              Business contact information, including, but not limited to, job title, place of
              employment, business phone number, business address.
            </span>
          </p>
        </td>
        <td class="c38" colspan="1" rowspan="1">
          <p class="c4">
            <span class="c10 c8">
              Performing the services or providing products, including, without limitation,
              providing customer services, verifying user information, facilitating access to the
              services and products.
            </span>
          </p>
          <p class="c4">
            <span class="c10 c8">
              Communicating with users who have requested support, information, or services.
            </span>
          </p>
          <p class="c4">
            <span class="c10 c8">
              To send you promotional communications about us and our products and services, to the
              extent permitted by law.
            </span>
          </p>
        </td>
        <td class="c15" colspan="1" rowspan="1">
          <p class="c34 c3">
            <span class="c10 c33"></span>
          </p>
        </td>
      </tr>
      <tr class="c40">
        <td class="c11 c18" colspan="1" rowspan="1">
          <p class="c4">
            <span class="c25">Audio, Electronic, Visual, or Similar Information</span>
          </p>
        </td>
        <td class="c36 c18" colspan="1" rowspan="1">
          <p class="c4">
            <span class="c8">Customer service calls to our call center.</span>
          </p>
        </td>
        <td class="c38 c18" colspan="1" rowspan="1">
          <p class="c4">
            <span class="c8">
              To monitor and improve our representatives&rsquo; performance, to perform quality
              assurance, and to provide customer services.
            </span>
          </p>
        </td>
        <td class="c15" colspan="1" rowspan="1">
          <p class="c34 c3">
            <span class="c10 c33"></span>
          </p>
        </td>
      </tr>
      <tr class="c40">
        <td class="c11 c18" colspan="1" rowspan="1">
          <p class="c4">
            <span class="c10 c25">Inferences</span>
          </p>
        </td>
        <td class="c36 c18" colspan="1" rowspan="1">
          <p class="c14">
            <span class="c10 c55">
              Preferences, behaviors, characteristics (including, for example, age, gender), and
              similar information inferred from the above categories of information.
            </span>
          </p>
          <p class="c4 c3">
            <span class="c10 c8"></span>
          </p>
        </td>
        <td class="c38 c18" colspan="1" rowspan="1">
          <p class="c4">
            <span class="c10 c8">
              Performing services or providing products to you, providing customer services,
              verifying user information, facilitating access to services or products.
            </span>
          </p>
          <p class="c4">
            <span class="c10 c8">
              To communicate with you and send you information about our products or services, or to
              respond to your request for information or products and services.
            </span>
          </p>
          <p class="c4">
            <span class="c8 c21">
              To send promotional communications about us, to the extent permitted by applicable
              law.
            </span>
          </p>
        </td>
        <td class="c56" colspan="1" rowspan="1">
          <p class="c34 c3">
            <span class="c10 c33"></span>
          </p>
        </td>
      </tr>
    </table>
    <p class="c4 c3">
      <span class="c21 c22"></span>
    </p>
    <p class="c4">
      <span class="c25 c21 c24">Sources</span>
      <span class="c8 c21">. Consistent with the Privacy&nbsp;</span>
      <span class="c1">Notice</span>
      <span class="c10 c8">
        &nbsp;above, we collect certain categories of personal data directly from you and, in some
        circumstances, third parties. The categories of sources that we collect personal data from
        in the prior 12 months includes the following:
      </span>
    </p>
    <ul class="c27 lst-kix_list_17-0 start">
      <li class="c4 c31 li-bullet-0">
        <span class="c10 c8">Our third party service providers;</span>
      </li>
      <li class="c4 c31 li-bullet-0">
        <span class="c10 c8">Advertising networks and cooperatives;</span>
      </li>
      <li class="c4 c31 li-bullet-0">
        <span class="c10 c8">Subsidiary and commonly owned companies</span>
      </li>
      <li class="c4 c31 li-bullet-0">
        <span class="c10 c8">
          Third party websites and mobile applications (e.g., websites that share information with
          us or our advertising partners);
        </span>
      </li>
      <li class="c4 c31 li-bullet-0">
        <span class="c10 c8">Social media companies;</span>
      </li>
      <li class="c4 c31 li-bullet-0">
        <span class="c10 c8">Our Websites; and</span>
      </li>
      <li class="c4 c31 li-bullet-0">
        <span class="c10 c8">Your communications with us.</span>
      </li>
    </ul>
    <p class="c4">
      <span class="c25 c21 c24">Disclosures to Third Parties</span>
      <span class="c8 c21">
        . Pursuant to and consistent with the purposes for collecting your information as set forth
        in this Privacy&nbsp;
      </span>
      <span class="c1">Notice</span>
      <span class="c10 c8">
        &nbsp;we disclose certain categories of personal data to third parties for a business (or
        other) purpose. The categories of personal information we disclose include:
      </span>
    </p>
    <ul class="c27 lst-kix_list_18-0 start">
      <li class="c4 c23 li-bullet-0">
        <span class="c8 c21 c43">Identifiers</span>
        <span class="c10 c8">
          : including but not limited to name, telephone number, address, age, email address, or
          date of birth;
        </span>
      </li>
      <li class="c4 c23 li-bullet-0">
        <span class="c8 c43 c21">Commercial Information</span>
        <span class="c10 c8">
          : including but not limited to information about the products and services you&rsquo;ve
          ordered and purchased from us;
        </span>
      </li>
      <li class="c4 c23 li-bullet-0">
        <span class="c8 c43 c21">Internet or other electronic network activity information</span>
        <span class="c10 c8">
          : including but not limited to computer and connection information, IP address, and
          cookies and other tracking technologies described further above; and
        </span>
      </li>
      <li class="c4 c23 li-bullet-0">
        <span class="c8 c43 c21">Inferences</span>
        <span class="c10 c8">: drawn from the above categories of personal information.</span>
      </li>
    </ul>
    <p class="c4">
      <span class="c8 c21">As described in this Privacy&nbsp;</span>
      <span class="c1">Notice</span>
      <span class="c10 c8">
        &nbsp;above, applicable business purposes for such disclosures include, without limitation,
        product and service fulfillment, internal operations, prevention of fraud and other harm,
        and legal compliance.
      </span>
    </p>
    <p class="c4">
      <span class="c10 c8">
        The categories of third parties to which we may share the above categories of information,
        include, without limitation:
      </span>
    </p>
    <ul class="c27 lst-kix_list_19-0 start">
      <li class="c4 c23 li-bullet-0">
        <span class="c10 c8">Our subsidiaries and commonly owned entities;</span>
      </li>
      <li class="c4 c23 li-bullet-0">
        <span class="c10 c8">
          Third party service providers: including, but not limited to, data analytics and
          advertising providers, fraud prevention providers, cloud storage providers, call center
          providers, IT service providers, delivery partners, and marketing companies;
        </span>
      </li>
      <li class="c4 c23 li-bullet-0">
        <span class="c10 c8">
          Third party businesses participating in advertising networks and cooperative; and
        </span>
      </li>
      <li class="c4 c23 li-bullet-0">
        <span class="c10 c8">Law Enforcement</span>
      </li>
    </ul>
    <p class="c4">
      <span class="c25 c21 c24">No Sensitive Personal Information.</span>
      <span class="c10 c8">
        &nbsp;We do not knowingly collect any personal information that would be considered
        &ldquo;sensitive&rdquo; under applicable law.
      </span>
    </p>
    <p class="c4 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c4">
      <span class="c25 c21 c24">Rights</span>
      <span class="c10 c8">
        . Applicable laws in your jurisdiction may grant you certain rights&mdash;subject to all
        applicable limitations, exemptions, or exceptions&mdash;regarding our collection and use of
        your personal information. These rights may include, to the extent applicable and to the
        extent granted under applicable law:
      </span>
    </p>
    <ul class="c27 lst-kix_list_20-0 start">
      <li class="c2 c23 li-bullet-0">
        <span class="c8 c28">
          The right to confirm whether personal information about you is processed and to access
          such personal information;
        </span>
      </li>
      <li class="c2 c23 li-bullet-0">
        <span class="c8 c28">
          The right to request the disclosure of (1) what personal information has been collected;
          (2) the categories of sources from which the personal information was collected; (3) the
          business or commercial purpose for collection or selling personal information; (4) the
          categories of third parties with whom we share personal information; and (5) the specific
          pieces of personal information we have collected about you (e.g., data portability);
        </span>
      </li>
      <li class="c2 c23 li-bullet-0">
        <span class="c8 c28">
          The right to request the deletion of your personal information that we have collected;
        </span>
      </li>
      <li class="c2 c23 li-bullet-0">
        <span class="c8 c28">
          The right to request the correction of your personal information that we collected;
        </span>
      </li>
      <li class="c2 c23 li-bullet-0">
        <span class="c8 c28">
          The right to opt-out of the selling of your personal information;
        </span>
      </li>
      <li class="c2 c23 li-bullet-0">
        <span class="c8 c28">
          The right to opt-out of the sharing of your personal information for cross contextual
          behavioral advertising purposes; and
        </span>
      </li>
      <li class="c2 c23 li-bullet-0">
        <span class="c8 c28">
          The right to not be discriminated against due to your exercise of your rights under
          applicable law. We do not discriminate against users for exercising the rights granted to
          them under applicable law.
        </span>
      </li>
    </ul>
    <p class="c4">
      <span class="c10 c8">
        We do not use your personal information to profile you in furtherance of decisions that
        produce legal or significantly similar effects (as may be defined under applicable law).
      </span>
    </p>
    <p class="c4 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c4">
      <span class="c10 c8">
        The above rights are only exercisable by you where the applicable law grants you the right
        being exerted and where no exception or exemption under applicable law applies. You may also
        have an authorized agent (if and only as permitted under applicable law) that you authorize
        to act on your behalf. If you have a authorized agent act on your behalf, we have the right
        to authenticate such agent&rsquo;s authority to act. To exercise the rights described herein
        (only to the extent applicable), you may submit a verifiable request to us by through the
        methods set forth under the &ldquo;Exercise Your Rights&rdquo; section below.
      </span>
    </p>
    <p class="c4 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c14">
      <span class="c25 c21 c24">Selling and Sharing of Personal Information</span>
      <span class="c8 c21">. </span>
      <span class="c10 c8">
        We do not sell your personal information as the concept of &ldquo;selling&rdquo; is
        traditionally understood as being the exchange of something for money. However, we share
        your personal information with third parties for the purpose of cross-contextual behavioral
        advertising purposes (e.g., targeted advertising and remarketing) and to third parties that
        participate in marketing cooperatives and networks that we also participate in. Applicable
        U.S. state data protection laws hold such disclosures and sharing to be considered
        &ldquo;selling&rdquo; or &ldquo;sharing&rdquo;. Therefore, you have the right to opt out of
        such selling and sharing. In the preceding 12 months, we have sold or shared the following
        categories of personal information with the following categories of third parties and for
        the following purposes:
      </span>
    </p>
    <p class="c14 c3">
      <span class="c10 c8"></span>
    </p>
    <table class="c53">
      <tr class="c7">
        <td class="c30" colspan="1" rowspan="1">
          <p class="c14">
            <span class="c10 c25">Category of Personal Information Sold or Shared</span>
          </p>
        </td>
        <td class="c13" colspan="1" rowspan="1">
          <p class="c14">
            <span class="c25">Category of Third-Party Recipient </span>
          </p>
        </td>
        <td class="c47" colspan="1" rowspan="1">
          <p class="c14">
            <span class="c25">Purpose</span>
          </p>
        </td>
      </tr>
      <tr class="c48">
        <td class="c30 c18" colspan="1" rowspan="2">
          <p class="c14">
            <span class="c10 c25">Identifiers</span>
          </p>
        </td>
        <td class="c13 c18" colspan="1" rowspan="1">
          <p class="c14">
            <span class="c10 c8">
              Analytics, marketing, advertising, and remarketing partners and service providers.
            </span>
          </p>
        </td>
        <td class="c16" colspan="1" rowspan="1">
          <p class="c14">
            <span class="c10 c8">
              Analytical and targeted advertising purposes (including, without limitation,
              cross-contextual behavioral advertising). Specifically, to target advertisements for
              goods and services and to display those advertisements on other websites.
            </span>
          </p>
        </td>
      </tr>
      <tr class="c48">
        <td class="c13 c18" colspan="1" rowspan="1">
          <p class="c14">
            <span class="c10 c8">Marketing cooperative participants</span>
          </p>
        </td>
        <td class="c16" colspan="1" rowspan="1">
          <p class="c14">
            <span class="c10 c8">
              For third party cooperative participants to provide you direct mail marketing and
              promotional communications about third-party provided services and products that may
              be of interest to you.
            </span>
          </p>
        </td>
      </tr>
      <tr class="c46">
        <td class="c18 c30" colspan="1" rowspan="1">
          <p class="c14">
            <span class="c10 c25">Commercial Information</span>
          </p>
        </td>
        <td class="c13 c18" colspan="1" rowspan="1">
          <p class="c14">
            <span class="c10 c8">Marketing cooperative participants</span>
          </p>
        </td>
        <td class="c16" colspan="1" rowspan="1">
          <p class="c14">
            <span class="c10 c8">
              For third party cooperative participants to provide you direct mail marketing and
              promotional communications about third-party provided services and products that may
              be of interest to you.
            </span>
          </p>
        </td>
      </tr>
      <tr class="c32">
        <td class="c30 c18" colspan="1" rowspan="1">
          <p class="c14">
            <span class="c10 c25">Internet or other electronic network activity information</span>
          </p>
        </td>
        <td class="c13 c18" colspan="1" rowspan="1">
          <p class="c14">
            <span class="c10 c8">
              Analytics, marketing, advertising, and remarketing partners and service providers.
            </span>
          </p>
        </td>
        <td class="c16" colspan="1" rowspan="1">
          <p class="c14">
            <span class="c10 c8">
              Analytical and targeted advertising purposes (including, without limitation,
              cross-contextual behavioral advertising). Specifically, to target advertisements for
              goods and services and to display those advertisements on other websites.
            </span>
          </p>
        </td>
      </tr>
    </table>
    <p class="c14 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c14">
      <span class="c8">
        You have the right to opt out of the selling or sharing of your personal information as
        described herein. You may exercise this right to opt out by contacting use through the
        methods set forth below under the &ldquo;Exercise Your Rights&rdquo;, or by clicking the
        &ldquo;
      </span>
      <span class="c8 c42">
        <a class="c9" target="_blank" href="https://privacy.leafhome.com/">
          DO NOT SELL OR SHARE MY PERSONAL INFORMATION
        </a>
      </span>
      <span class="c10 c8">&rdquo;.</span>
    </p>
    <p class="c14 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c14">
      <span class="c8">
        We do not knowingly sell or share for cross-contextual behavioral advertising purposes, the
        personal information of anyone under the age of 16 years old. See the &ldquo;
      </span>
      <span class="c8 c43">Children</span>
      <span class="c10 c8">&rdquo; section of this Privacy Notice below for more information.</span>
    </p>
    <p class="c4 c3">
      <span class="c12 c29 c17"></span>
    </p>
    <p class="c4">
      <span class="c29 c17">Exercise Your Rights</span>
    </p>
    <p class="c19">
      <span class="c8 c21">If this Privacy&nbsp;</span>
      <span class="c1">Notice</span>
      <span class="c8 c21">
        &nbsp;expressly stated above that you have certain rights or applicable law grants you
        certain rights as it relates to this Privacy&nbsp;
      </span>
      <span class="c1">Notice</span>
      <span class="c10 c8">
        &nbsp;and Collected Information, you may submit a verifiable request to us by
      </span>
    </p>
    <p class="c2">
      <span class="c10 c8">Mailing:</span>
    </p>
    <p class="c2 c37">
      <span class="c10 c8">Leaf Home</span>
    </p>
    <p class="c2 c37">
      <span class="c10 c8">Attn: IT Compliance</span>
    </p>
    <p class="c2 c37">
      <span class="c10 c8">1595 Georgetown Road</span>
    </p>
    <p class="c2 c37">
      <span class="c10 c8">Hudson, Ohio 44236</span>
    </p>
    <p class="c2">
      <span class="c8 c21">Emailing:&nbsp;</span>
      <span class="c20 c8">
        <a class="c9" target="_blank" href="mailto:privacy@leafhome.com">
          privacy@leafhome.com
        </a>
      </span>
    </p>
    <p class="c41">
      <span class="c8 c21">Telephone: (</span>
      <span class="c17">877) 547-1633</span>
    </p>
    <p class="c41 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c41">
      <span class="c10 c8">Visiting: https://privacy.leafhome.com/ </span>
    </p>
    <p class="c19">
      <span class="c10 c8">
        Only you or an authorized agent (if and only as permitted under applicable law) that you
        authorize to act on your behalf, may make a verifiable consumer request related to your
        personal information. If you have an authorized agent act on your behalf, we have the right
        to authenticate such agent&rsquo;s authority to act. Without limiting the foregoing, the
        verifiable consumer request must:
      </span>
    </p>
    <ul class="c27 lst-kix_list_22-0 start">
      <li class="c2 c26 li-bullet-0">
        <span class="c10 c8">
          Provide sufficient information that allows us to reasonably verify you are the person
          about whom we collected personal information or an authorized representative.
        </span>
      </li>
      <li class="c2 c26 li-bullet-0">
        <span class="c10 c8">
          Describe your request with sufficient detail that allows us to properly understand,
          evaluate, and respond to it.
        </span>
      </li>
    </ul>
    <p class="c19">
      <span class="c10 c8">
        We cannot respond to your request or provide you with personal information if we cannot
        verify your identity or authority to make the request and confirm the personal information
        relates to you.&nbsp; Making a verifiable consumer request does not require you to create an
        account with us.&nbsp; We will only use personal information provided in a verifiable
        consumer request to verify the requestor&rsquo;s identity or authority to make the request.
      </span>
    </p>
    <p class="c19">
      <span class="c10 c8">
        We will respond to your request within the applicable timeframe required under applicable
        law. If we deny your request, you can appeal such denial by contacting dpo@leafhome.com
      </span>
    </p>
    <p class="c19 c3">
      <span class="c12 c29 c17"></span>
    </p>
    <p class="c4 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c4">
      <span class="c29 c17">Additional Information For Canadian Residents</span>
    </p>
    <p class="c4">
      <span class="c8 c21">The following section of this Privacy&nbsp;</span>
      <span class="c1">Notice</span>
      <span class="c10 c8">
        &nbsp;only applies to Canadian residents. Under various Canadian laws and regulations, you
        have certain rights in regard to our collection of personal information. These rights
        include:
      </span>
    </p>
    <ul class="c27 lst-kix_list_21-0 start">
      <li class="c2 c26 li-bullet-0">
        <span class="c10 c8">The right to request access to your personal information;</span>
      </li>
      <li class="c2 c26 li-bullet-0">
        <span class="c10 c8">The right to request corrections to your personal information;</span>
      </li>
      <li class="c2 c26 li-bullet-0">
        <span class="c8 c21">
          The right to lodge a complaint with the Office of the Privacy Commissioner of Canada
          and/or the applicable Canadian provincial regulatory body if you consider that the
          collection and use of your personal information violates this Privacy&nbsp;
        </span>
        <span class="c1">Notice</span>
        <span class="c10 c8">&nbsp;or applicable law.</span>
      </li>
    </ul>
    <p class="c4">
      <span class="c10 c8">
        The above rights are only exercisable by you where applicable law in the jurisdiction in
        which you reside actually grant you the right being exerted.
      </span>
    </p>
    <p class="c19">
      <span class="c29 c17">Accuracy and Information Security</span>
    </p>
    <p class="c2">
      <span class="c1 c12">
        Personal information you provide to us should be relevant to the purposes for which it is to
        be used, and, to the extent necessary for those purposes, should be accurate, complete, and
        up-to-date.
      </span>
    </p>
    <p class="c2 c3">
      <span class="c10 c8"></span>
    </p>
    <p class="c2">
      <span class="c1">
        Company is committed to taking steps designed to protect the security of your personal
        information. Company employs reasonable and appropriate physical, electronic, and managerial
        measures designed to help safeguard the security and integrity of information under our
        control, including a variety of reasonable and appropriate security technologies and
        procedures designed to help protect your personal information from unauthorized access, use,
        or disclosure. The storage and communication of information can never be completely secure,
        however, so we do not and cannot guarantee that communications or other information you
        submit or otherwise collected will be completely secure. If you become aware of any breach
        of Services security or of this Privacy Notice, please notify us using the contract
        information below.
      </span>
    </p>
    <p class="c19">
      <span class="c29 c17">Storage of Information</span>
    </p>
    <p class="c2">
      <span class="c1">
        Collected Information will be stored and processed in the United States and in any country
        where our or our suppliers&rsquo; servers, databases, or facilities are located or operated.
        By accessing, receiving, or using the Services, you acknowledge and agree that information
        may be transferred outside of the country from which you access the Services and that such
        countries&rsquo; data protection and related laws, regulations, and rules may be different,
        and may require a lower standard of protection, than those of the jurisdiction from where
        you access the Services.
      </span>
    </p>
    <p class="c19">
      <span class="c29 c17">Embedded Content</span>
    </p>
    <p class="c4">
      <span class="c10 c8">
        The Services incorporate content, including feeds, scripts embedded in the Service&rsquo;s
        code, and visible content (e.g., videos), provided by third parties. In some cases, those
        third parties collect data about how you interact with their content. For example, Google
        Maps or YouTube.
      </span>
    </p>
    <p class="c4 c3">
      <span class="c22 c21"></span>
    </p>
    <p class="c4">
      <span class="c25 c21 c24">Google Maps.&nbsp;</span>
      <span class="c8 c21">
        Our Services may include Google Maps features and content. Google Maps features and content
        is subject to the then-current versions of Google Maps/Google Earth Additional Terms of
        Service at&nbsp;
      </span>
      <span class="c20 c8">
        <a class="c9" target="_blank" href="https://maps.google.com/help/terms_maps.html">
          https://maps.google.com/help/terms_maps.html
        </a>
      </span>
      <span class="c8 c21">&nbsp;and Google Privacy Policy at&nbsp;</span>
      <span class="c20 c8">
        <a class="c9" target="_blank" href="https://www.google.com/policies/privacy/">
          https://www.google.com/policies/privacy/
        </a>
      </span>
      <span class="c10 c8">.</span>
    </p>
    <p class="c4 c3">
      <span class="c22 c21"></span>
    </p>
    <p class="c4">
      <span class="c25 c21 c24">YouTube</span>
      <span class="c8 c21 c24">.&nbsp;</span>
      <span class="c8 c21">
        Our Services may use YouTube to make content in video format available to you. By accessing
        a part of the Services where videos are available, watching an embedded video, or otherwise
        interacting with any content made available through YouTube, you signify your agreement with
        YouTube&rsquo;s&nbsp;
      </span>
      <span class="c20 c8">
        <a class="c9" target="_blank" href="https://www.youtube.com/static?template%3Dterms">
          terms and conditions
        </a>
      </span>
      <span class="c8 c21">
        . YouTube collects and otherwise has access to usage data (e.g., what videos you accessed
        and watched) through videos embedded in the Services as further described in{' '}
      </span>
      <span class="c20 c8">
        <a class="c9" target="_blank" href="https://policies.google.com/privacy?hl%253Den">
          YouTube&rsquo;s Privacy Policy
        </a>
      </span>
      <span class="c8 c21">
        . YouTube adheres to Google&#39;s privacy policies and principles, part of which allow you
        to control certain privacy settings and which data are collected. For more information,
        please visit&nbsp;
      </span>
      <span class="c20 c8">
        <a
          class="c9"
          target="_blank"
          href="https://www.youtube.com/howyoutubeworks/user-settings/privacy/"
        >
          https://www.youtube.com/howyoutubeworks/user-settings/privacy/
        </a>
      </span>
      <span class="c10 c8">.</span>
    </p>
    <p class="c19">
      <span class="c29 c17">Third-Party Websites and Services</span>
    </p>
    <p class="c2">
      <span class="c1">
        The Services may link to, or be linked to, websites, applications, or services not
        maintained or controlled by Company. Company is not responsible for the privacy policies or
        practices of any third parties or any third-party websites, applications, or services. This
        Privacy Notice does not apply to any third-party websites, applications, or services or to
        any personal or other information that you may provide to third parties. We encourage you to
        read the privacy policy and applicable terms for each website, application, and service you
        visit, use, or interact with.
      </span>
    </p>
    <p class="c19">
      <span class="c29 c17">Children</span>
    </p>
    <p class="c2">
      <span class="c1">
        The Services are not directed at children under 16 years of age. Company does not knowingly
        collect or use information from children under 16 through the Services.
      </span>
    </p>
    <p class="c19">
      <span class="c29 c17">How to Contact Us</span>
    </p>
    <p class="c49">
      <span class="c1">
        If you have any questions or concerns regarding this Privacy Notice, to change your
        communication preferences, or to access or update your personal information as it exists in
        our records, contact us at: Leaf Home, Attn: IT Compliance 1595 Georgetown Road Hudson, Ohio
        44236;&nbsp;
      </span>
      <span class="c8 c20">
        <a class="c9" target="_blank" href="mailto:privacy@leafhome.com">
          privacy@leafhome.com
        </a>
      </span>
      <span class="c1">; or&nbsp;(234) 224-9293</span>
    </p>
    <p class="c3 c54">
      <span class="c10 c39"></span>
    </p>
    <div>
      <p class="c14">
        <span class="c10 c35">24431641 v2</span>
      </p>
    </div>
  </>
);

export { PrivacyPolicy2024OctoberDoc };
